.wrapper {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
}

.logo {
  margin: 52px 0 44px;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  white-space: nowrap;
  color: #e7c416;
  transition: color 0.2s ease;
  &:hover {
    text-decoration: none;
  }
}
