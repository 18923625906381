$color-yellow: #e7c416;
$color-yellow-transparent: #e7c41688;

$breakpoints: (
  'phone-vertical': 320px,
  'phone-landspace': 576px,
  'tablet-vertical': 768px,
  'tablet-landscape': 1080px,
  'laptop': 1280px,
  'desktop-medium': 1366px,
  'desktop-large': 1920px,
  'desktop-extra-large': 2560px,
) !default;

@function breakpoint($value) {
  @if map-has-key($breakpoints, $value) {
    @return map-get($breakpoints, $value);
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$value}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
  @return 0;
}

@mixin mf-breakpoint-at($val) {
  @if map-has-key($breakpoints, $val) {
    @media (min-width: map-get($breakpoints, $val)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$val}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin text {
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.3);
  color: #fff;
}

@mixin text-title {
  @include text();
  font-family: 'Oswald';
}

@mixin text-regular {
  @include text();

  a {
    color: #eff;
    text-decoration: none;
  }
}
