@import '../styles/base.scss';

.list {
  display: flex;
  align-items: flex-start;
  flex-flow: row wrap;
  gap: 12px;
  margin: 0 -20px 0 -20px;
}

.card {
  display: flex;
  min-height: 0;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;

  width: 350px;
  height: 130px;

  border-radius: 20px;

  padding: 0 16px;

  transition: 0.5s all;

  border: 1px solid transparent;
  text-decoration: none !important;

  &:hover {
    color: $color-yellow;
    text-shadow: none;
    border: 1px solid $color-yellow-transparent;

    .title {
      color: $color-yellow;
    }
  }
}

.title {
  @include text-title();

  color: #ffffff88;
  margin-bottom: 0;
}

.description {
  @include text-regular();
  flex: 1 1 0;
  min-width: 0;
  line-height: 1.3em;
  overflow: hidden;
  color: #ffffff44 !important;
  -webkit-mask-image: linear-gradient(180deg, #000 35%, transparent);
}

.locales {
  display: flex;
  font-size: 12px;
  column-gap: 24px;
}
