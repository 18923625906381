@import '../styles/base.scss';

.wrapper {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  margin: 0 2em;
  max-width: none;

  @include mf-breakpoint-at('tablet-vertical') {
    margin: 0 auto;
    max-width: breakpoint('tablet-vertical');
  }

  @include mf-breakpoint-at('tablet-landscape') {
    margin: 0 auto;
    max-width: breakpoint('tablet-vertical');
  }
}
