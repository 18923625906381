@mixin link {
  text-decoration: none;
  text-transform: uppercase;
  color: #eeeeee55;
  font-size: 10px;
  transition: all 0.5s ease;
}

.link {
  @include link();
  &:hover {
    color: #eeeeeeff;
    text-decoration: underline;
  }
}

.disabled {
  @include link();
  color: #eeeeee33;
}
